import React, { useState } from 'react';
import { Typography, EditIcon, VisibilityIcon, VisibilityOffIcon } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import EditTeamDetailsModal from '../../../components/Modals/EditTeamDetailsModal';

import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { getAdjustedTimeOfDay } from '../../../utils/display';
import { weekdays, openClosedStatusTypes, getTypeForId } from '../../../utils/types';

const DetailsTab = ({ teamDetails }) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP, SMALL_MEDIUM, SMALL_BOOK } = TypographySizes;
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { timeOfDay, dayOfWeek, registrationPin, homeCourtLocation, name, teamRegistrationAvailabilityStatusTypeId } = teamDetails || {};
    const teamLocation = homeCourtLocation?.placeName || homeCourtLocation?.formattedAddress || '';
    const registrationStatus = getTypeForId(teamRegistrationAvailabilityStatusTypeId, openClosedStatusTypes);

    let time = null;
    if (teamDetails?.timeOfDay) {
        time = getAdjustedTimeOfDay({ timeOfDay, format: 'LT' });
    }

    let timeSlotDay = '';
    if (teamDetails?.dayOfWeek) {
        timeSlotDay = weekdays.find((weekday) => weekday.description === dayOfWeek);
    }

    const handleOnEditClick = () => {
        setShowEditModal(true);
    };

    const handleOnCloseEditModal = () => {
        setShowEditModal(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box maxWidth={750}>
            <Box display="flex" justifyContent="space-between" alignContent="center">
                <Typography color="black" category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>Details</Typography>
                <IconButton onClick={handleOnEditClick} aria-label="Edit team details"><EditIcon color={theme.appColors.interactionBlue} size={16} /></IconButton>
            </Box>
            <Grid />
            <Paper sx={{ p: 4 }}>
                <Box>
                    <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>General</Typography>
                    <Box display="flex" justifyContent="space-between" alignContent="center">
                        <Box flex={3}>
                            <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Team name</Typography>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>{name}</Typography>
                        </Box>
                        <Box flex={2}>
                            <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Home court</Typography>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>{teamLocation}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>League Play Time Slot</Typography>
                    <Box display="flex" justifyContent="space-between">
                        <Box flex={3}>
                            <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Day</Typography>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>{timeSlotDay?.description}</Typography>
                        </Box>
                        <Box flex={2}>
                            <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Match start time</Typography>
                            <Typography align="left" category={SECONDARY} size={SMALL_BOOK}>{time}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>Team Registration</Typography>
                    <Box display="flex" justifyContent="space-between" alignContent="center">
                        <Box flex={3}>
                            <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Team player registrations</Typography>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>{registrationStatus?.description}</Typography>
                        </Box>
                        <Box flex={2}>
                            <Box display="flex" alignItems="center">
                                <Typography category={SECONDARY} size={SMALL_MEDIUM} color={theme.appColors.blueGrey}>Password</Typography>
                                <IconButton
                                    disabled={!registrationPin}
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <VisibilityOffIcon size={16} color={theme.appColors.interactionBlue} /> : <VisibilityIcon size={16} color={theme.appColors.interactionBlue} />}
                                </IconButton>
                            </Box>
                            {registrationPin ? (
                                <Typography sx={{ flex: 2 }} category={SECONDARY} size={SMALL_BOOK}>{showPassword ? registrationPin : '********'}</Typography>
                            ) : (
                                <Typography category={SECONDARY} size={SMALL_BOOK}>None</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <EditTeamDetailsModal
                title="Edit team details"
                isOpen={showEditModal}
                teamDetails={teamDetails}
                onClose={handleOnCloseEditModal}
                onSaveClick={handleOnCloseEditModal}
            />
        </Box>
    );
};

export default DetailsTab;
