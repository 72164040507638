import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import LoadingIndicator from '../LoadingIndicator';
import PasswordInput from '../Form/PasswordInput';

const dialogContent =
    'Please enter the password or PIN you received from the team captain or organizer. Don\'t have a PIN or password? Message the team captain.';

const RegistrationPinModal = ({
    isOpen,
    onPinSubmitted,
    onRequestClose,
    isValidatingPin = true,
    teamName,
    isInvalidPassword,
}) => {
    const defaultValues = { pin: '' };
    const {
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        control,
    } = useForm({ defaultValues, mode: 'all' });

    const handleOnSubmit = (formValues) => {
        onPinSubmitted(formValues.pin);
        setValue('pin', '');
    };

    const handleOnRequestClose = () => {
        onRequestClose();
    };

    useEffect(() => {
        if (isInvalidPassword) {
            setError('pin', isInvalidPassword);
        }
    }, [isInvalidPassword]);

    return (
        <Dialog open={isOpen} onClose={handleOnRequestClose}>
            <DialogTitle align="center">Enter Team Password</DialogTitle>
            <DialogTitle align="center">{teamName}</DialogTitle>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <DialogContent>
                    <DialogContentText mb={3} variant="h6" align="center">
                        Registration for this team is password protected.
                    </DialogContentText>
                    <DialogContentText my={3}>{dialogContent}</DialogContentText>
                    {isValidatingPin ? (
                        <LoadingIndicator size={40} />
                    ) : (
                        <PasswordInput
                            error={!!errors.pin}
                            name="pin"
                            label="Password"
                            control={control}
                            helperText={
                                !!errors.pin &&
                                'The password you entered is invalid. Please try again or message the team captain'
                            }
                        />
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <Button variant="light" disabled={isValidatingPin} onClick={handleOnRequestClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" disabled={isValidatingPin} type="submit">
                        Validate
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RegistrationPinModal;
