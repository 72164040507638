import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@universal-tennis/ui-shared';
import { Elements } from '@stripe/react-stripe-js';
import RegistrationConfirmation from '../RegistrationConfirmation';
import TeamSelection from '../TeamSelection';
import PasswordConfirmation from '../PasswordConfirmation';
import { SESSION_PAGE, TEAM_PAGE } from '../../../utils/constants';

const SELECT_TEXT = 'Select a team to join';

const RegistrationStepper = ({ onPasscodeSubmitted, onTeamRemoveClick, pageName, stripeInstance, ...rest }) => {
    const isTeamPage = pageName === TEAM_PAGE;
    const [activeStep, setActiveStep] = React.useState(0);
    const { teamData, onTeamSelectionClick, teams } = rest;
    const { teamName } = teamData || '';

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleOnTeamCardClick = (id) => {
        onTeamSelectionClick(id);
    };

    const handleOnPinSubmitted = async (pin) => {
        const isValid = await onPasscodeSubmitted(pin);
        if (isValid) {
            await handleNext();
        }
    };

    const steps = isTeamPage
        ? [{ label: 'Confirm Details', content: <Elements stripe={stripeInstance}><RegistrationConfirmation {...rest} /></Elements> }]
        : [
            {
                label: 'Team Selection',
                content: <TeamSelection {...rest} onTeamCardClick={handleOnTeamCardClick} />,
            },
            {
                label: 'Password Confirmation',
                content: <PasswordConfirmation {...rest} onPinSubmitted={handleOnPinSubmitted} />,
            },
            {
                label: 'Confirm Details',
                content: (<Elements stripe={stripeInstance}><RegistrationConfirmation {...rest} /></Elements>
                )
            },
        ];

    const handleLast = () => {
        setActiveStep(steps.length - 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!teamData.hasRegistrationPin) {
                await onPasscodeSubmitted();
                handleLast();
            } else {
                handleNext();
            }
        };
        if (teamName && pageName === SESSION_PAGE) {
            fetchData();
        }
    }, [teamName]);

    return (
        <Box>
            {teams?.length > 0 || (isTeamPage && teamName) ? (
                <Typography category="secondary" size="large-medium">
                    {teamName || SELECT_TEXT}
                </Typography>
            ) : null}
            <>
                {steps[activeStep].content}
            </>
        </Box>
    );
};
export default RegistrationStepper;
