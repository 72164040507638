import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Typography } from '@universal-tennis/ui-shared';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ManageSessionTeamCaptain from '../../../components/ManageSessionTeamCaptain';
import LoadingIndicator from '../../../components/LoadingIndicator';
import FullPageModal from '../../../components/Modals/FullPageModal';
import Input from '../../../components/Form/Input';
import Location from '../../../components/Form/Location';
import PasswordInput from '../../../components/Form/PasswordInput';
import TimePicker from '../../../components/Form/TimePicker';
import Select from '../../../components/Form/Select';
import { CustomSwitch } from '../../../components/Styled';

import { defaultGridSpacing, SharedUICategories, TypographySizes } from '../../../utils/constants';
import { weekdays, LeagueSessionTypes, StatusTypes } from '../../../utils/types';
import { getLocationData } from '../../../utils/location';
import { getAdjustedTimeOfDay } from '../../../utils/display';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { manageTeamModalPropTypes } from '../../../utils/proptypes';

import { useGetTeamQuery, useUpdateTeamMutation, useAddCaptainMutation, useRemoveCaptainMutation } from '../../../api/leaguesApi';

const ManageTeamModal = ({ isOpen, onClose, teamId, onSaveClick }) => {
    const dispatch = useDispatch();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP, SMALL_BOOK } = TypographySizes;
    const { OPEN, CLOSED } = StatusTypes;
    const { data: teamDetails, isLoading: isLoadingTeam, isFetching } = useGetTeamQuery(teamId);
    const [addCaptain, { isLoading: isAddingCaptain }] = useAddCaptainMutation();
    const [removeCaptain, { isLoading: isRemovingCaptain }] = useRemoveCaptainMutation();
    const { TOURNAMENT, MULTI_ROUND } = LeagueSessionTypes;
    const [sessionTypeId, setSessionTypeId] = useState();
    const [originalLocation, setOriginalLocation] = useState();
    const [originalFormData, setOriginalFormData] = useState();
    const [teamRegistrationStatus, setTeamRegistrationStatus] = useState(true);
    const [updatedTeamRegistrationStatus, setUpdatedTeamRegistrationStatus] = useState(OPEN);
    const registrationStatusDescription = teamRegistrationStatus ? 'Open' : 'Closed';
    const isUpdatingCaptains = isAddingCaptain || isRemovingCaptain || isFetching;

    const [updateTeam] = useUpdateTeamMutation();

    const defaultValues = {
        name: '',
        registrationPin: '',
        teamRegistrationAvailabilityStatusTypeId: 1,
        dayOfWeek: '',
        location: '',
        matchStartTimeUtc: moment(),
    };

    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (teamDetails) {
            const {
                name,
                timeOfDay,
                homeCourtLocation,
                teamRegistrationAvailabilityStatusTypeId,
                registrationPin,
                sessionTypeId: teamSessionTypeId,
                dayOfWeek,
            } = teamDetails;

            let time = null;
            if (timeOfDay) {
                const adjustedTime = getAdjustedTimeOfDay({ timeOfDay });
                time = moment(adjustedTime, 'hh:mm:ss');
            }

            let timeSlotDay = '';
            if (dayOfWeek) {
                timeSlotDay = weekdays.find((weekday) => weekday.description === dayOfWeek);
            }

            const canRegister = teamRegistrationAvailabilityStatusTypeId === 1;
            const teamLocation = homeCourtLocation?.placeName || homeCourtLocation?.formattedAddress || '';
            const formData = {
                name,
                teamRegistrationAvailabilityStatusTypeId,
                location: teamLocation,
                matchStartTimeUtc: time,
                dayOfWeek: timeSlotDay.id,
                registrationPin: registrationPin || '',
            };

            setTeamRegistrationStatus(canRegister);
            setSessionTypeId(teamSessionTypeId);
            setOriginalLocation(homeCourtLocation);
            setOriginalFormData(formData);
            reset(formData);
        }
    }, [teamDetails]);

    const handleFormSubmit = async (formValues) => {
        const team = { ...formValues };
        const { matchStartTimeUtc, location: teamLocation } = formValues;

        if (sessionTypeId === MULTI_ROUND) {
            const matchTime = matchStartTimeUtc.clone();
            const utcOffset = matchTime.utcOffset();
            const adjustedTime = matchTime.subtract(utcOffset, 'minutes');
            const hours = adjustedTime.get('hour');
            const minutes = adjustedTime.get('minutes');

            team.timeOfDay = `${hours}:${minutes}`;
        }

        team.teamRegistrationAvailabilityStatusTypeId = parseInt(updatedTeamRegistrationStatus);

        if (dirtyFields.location) {
            const formattedLocation = await getLocationData(teamLocation);
            team.homeCourtLocation = formattedLocation;
        } else {
            team.homeCourtLocation = originalLocation;
        }

        if (sessionTypeId === TOURNAMENT) {
            delete team.dayOfWeek;
            delete team.timeOfDay;
        }

        delete team.location;
        delete team.matchStartTimeUtc;
        delete team.sessionId;

        try {
            await updateTeam({ teamId: teamDetails?.id, team, sessionId: teamDetails?.sessionId }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnSave = () => {
        handleSubmit(handleFormSubmit)();
        onSaveClick();
    };

    const handleOnClose = () => {
        onClose();
        reset(originalFormData);
    };

    const handleOnRemoveTeamCaptain = async (removedCaptain) => {
        try {
            await removeCaptain({ teamId, captainId: removedCaptain.id });
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnAddTeamCaptain = async (addedCaptain) => {
        try {
            await addCaptain({ teamId, captain: { memberId: addedCaptain.memberId } }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const onHandleRegistrationStatusSwitch = () => {
        const newId = updatedTeamRegistrationStatus === OPEN ? CLOSED : OPEN;
        setTeamRegistrationStatus((prevStatus) => !prevStatus);
        setUpdatedTeamRegistrationStatus(newId);
    };

    return (
        <FullPageModal
            isOpen={isOpen}
            title={`Manage ${teamDetails?.name ?? ''}`}
            ctaTitle="Save"
            onClose={handleOnClose}
            onCtaClick={handleOnSave}
        >
            <Grid justifyContent="center" mt={3} mb={10}>
                {isLoadingTeam ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <form>
                            <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                Details
                            </Typography>
                            <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                                <Grid container spacing={defaultGridSpacing}>
                                    <Grid item xs={12} lg={6}>
                                        <Input name="name" label="Team Name" hasError={!!errors.name} isRequired control={control} />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Location name="location" label="Home Court" error={!!errors.location} control={control} />
                                    </Grid>
                                </Grid>
                            </Paper>
                            {sessionTypeId === MULTI_ROUND && (
                                <>
                                    <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                        League Play Time Slot
                                    </Typography>
                                    <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                                        <Grid container spacing={defaultGridSpacing}>
                                            <Grid item xs={12} lg={6}>
                                                <Select
                                                    name="dayOfWeek"
                                                    label="Day"
                                                    options={weekdays}
                                                    labelId="day-label"
                                                    isFullWidth
                                                    control={control}
                                                />
                                            </Grid>
                                            <Grid item lg={6} xs={12}>
                                                <TimePicker name="matchStartTimeUtc" label="Match Start Time" control={control} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </>
                            )}
                            <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                Registration Settings
                            </Typography>
                            <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                                        Team Player Registration
                                    </Typography>
                                    <Box display="flex" gap={1} alignItems="center">
                                        <Typography category={SECONDARY} size={SMALL_BOOK}>
                                            {registrationStatusDescription}
                                        </Typography>
                                        <CustomSwitch
                                            control={control}
                                            checked={teamRegistrationStatus}
                                            inputProps={{ 'aria-label': `Switch` }}
                                            onChange={onHandleRegistrationStatusSwitch}
                                        />
                                    </Box>
                                </Grid>
                            </Paper>
                            <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                Password
                            </Typography>
                            <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                                <Typography mb={2.5} category={SECONDARY} size={SMALL_BOOK}>
                                    You can set an optional password that your players will need in order to register for this team.
                                </Typography>
                                <PasswordInput name="registrationPin" label="Password" control={control} />
                            </Paper>
                        </form>
                        <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Captains
                        </Typography>
                        <ManageSessionTeamCaptain
                            isLoadingTeam={isLoadingTeam}
                            captains={teamDetails?.captains}
                            isUpdatingCaptains={isUpdatingCaptains}
                            onAddCaptain={handleOnAddTeamCaptain}
                            onRemoveCaptain={handleOnRemoveTeamCaptain}
                        />
                    </>
                )}
            </Grid>
        </FullPageModal>
    );
};

ManageTeamModal.propTypes = {
    ...manageTeamModalPropTypes,
};

export default ManageTeamModal;
