import React from 'react';
import config from '../../config';
import { getClickAttrs } from '../../utils/analytics';
import Img from '../Img';

import styles from './footer.module.scss';

const { marketingAppHost, links } = config;

const Footer = () => {
    return (
        <footer className={`footer ${styles.footer}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-9">
                        <div className="d-flex text-small links">
                            <ul className="list-inline">
                                <li className="list-inline-item text-medium">&copy; Copyright 2023 Universal Tennis &middot;</li>
                                <li className="list-inline-item">
                                    <a
                                        className="text-gray"
                                        href={`${marketingAppHost}/privacy`}
                                        {...getClickAttrs({
                                            category: 'Footer',
                                            location: 'Footer Link',
                                            context: 'Privacy Policy',
                                        })}
                                    >
                                        Privacy Policy &middot;
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        className="text-gray"
                                        href={`${marketingAppHost}/tos`}
                                        {...getClickAttrs({
                                            category: 'Footer',
                                            location: 'Footer Link',
                                            context: 'Terms',
                                        })}
                                    >
                                        Terms &middot;
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        className="text-gray"
                                        href={`${marketingAppHost}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        {...getClickAttrs({
                                            category: 'Footer',
                                            location: 'Footer Link',
                                            context: `${config.brandedUrl}`,
                                        })}
                                    >
                                        UTR Sports &middot;
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        className="text-gray"
                                        href={`${links.supportHome}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        {...getClickAttrs({
                                            category: 'Footer',
                                            location: 'Footer Link',
                                            context: 'Support',
                                        })}
                                    >
                                        Support &middot;
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        className="text-gray"
                                        href={`${marketingAppHost}/getintouch`}
                                        {...getClickAttrs({
                                            category: 'Footer',
                                            location: 'Footer Link',
                                            context: 'Contact Us',
                                        })}
                                    >
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex icons">
                            <a
                                className="app-store-logo"
                                href={config.links.appStoreUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'App Store',
                                })}
                            >
                                <img alt="" src="/img/logos/app-store-badge.svg" />
                            </a>
                            <a
                                className="app-store-logo"
                                href={config.links.googlePlayUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'Google play',
                                })}
                            >
                                <img alt="" src="/img/logos/google-play-icon.svg" />
                            </a>
                            <a
                                href="https://www.youtube.com/c/myutr"
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'Youtube',
                                })}
                            >
                                <div className="footer-icon">
                                    <Img src="/img/social/youtube-icon--light.svg" />
                                </div>
                            </a>
                            <a
                                href="https://www.facebook.com/UTRSports"
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'Facebook',
                                })}
                            >
                                <div className="footer-icon">
                                    <Img src="/img/social/facebook-icon--light.svg" />
                                </div>
                            </a>
                            <a
                                href="https://www.instagram.com/utr_sports/"
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'Instagram',
                                })}
                            >
                                <div className="footer-icon">
                                    <Img src="/img/social/instagram-icon--light.svg" />
                                </div>
                            </a>
                            <a
                                href="https://www.tiktok.com/@utr_sports_"
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'tiktok',
                                })}
                            >
                                <div className="footer-icon">
                                    <Img src="/img/social/tiktok-icon--light.svg" />
                                </div>
                            </a>
                            <a
                                href={`https://twitter.com/${config.twitterAccount}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                {...getClickAttrs({
                                    category: 'Footer',
                                    location: 'Footer Link',
                                    context: 'Twitter',
                                })}
                            >
                                <div className="footer-icon">
                                    <Img src="/img/social/twitter-icon--light.svg" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
