import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import LoadingIndicator from '../../../components/LoadingIndicator';
import SessionsTable from '../../../components/Tables/SessionsTable';

const SessionsTab = ({ sessions, conferenceId, isLoading, onRemoveSession }) => {
    const navigate = useNavigate();

    const handleOnCreateSessionClick = () => {
        navigate(`/leagues/${conferenceId}/session/create`);
    };

    const handleOnManageClick = (sessionId) => {
        navigate(`/leagues/session/${sessionId}/manage`);
    };

    const handleOnViewClick = (sessionId) => {
        navigate(`/leagues/session/${sessionId}`);
    };

    const handleOnRemoveClick = (sessionId, hasRegisteredPlayers) => {
        onRemoveSession(sessionId, hasRegisteredPlayers);
    };

    return (
        <Container>
            <Box my={4} display="flex" justifyContent="flex-end" alignContent="center">
                <Button onClick={handleOnCreateSessionClick} variant="dark">
                    Create New Session
                </Button>
            </Box>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Box mt={4}>
                    {!sessions?.length ? (
                        <Typography align="center" variant="h5">
                            No sessions have been created for this conference.
                        </Typography>
                    ) : (
                        <SessionsTable
                            conferenceSessions={sessions}
                            onManageClick={handleOnManageClick}
                            onRemoveClick={handleOnRemoveClick}
                            onViewClick={handleOnViewClick}
                        />
                    )}
                </Box>
            )}
        </Container>
    );
};

export default SessionsTab;
