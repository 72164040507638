import React from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import RegistrationStepper from './RegistrationStepper';

const RegistrationDrawer = ({ isOpen, onClose, isLoading, ...rest }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const drawerWidth = isLargeScreen ? 450 : '100%';

    const handleOnCloseButtonClicked = () => {
        onClose();
    };

    return (
        <Drawer PaperProps={{ sx: { width: drawerWidth } }} variant="temporary" anchor="right" open={isOpen}>
            <IconButton sx={{ position: 'absolute', top: 20, right: 20 }} onClick={handleOnCloseButtonClicked} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Box mt={10} p={3}>
                <RegistrationStepper {...rest} />
            </Box>
        </Drawer>
    );
};

export default RegistrationDrawer;
